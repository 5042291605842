import React from 'react';
import Slider from "react-slick";

import slide_one from "../../resources/img/slide_one.jpg";
import slide_one_mobile from "../../resources/img/slide_one_mobile.jpg";
import slide_two from "../../resources/img/slide_two.jpg";
import slide_two_mobile from "../../resources/img/slide_two_mobile.jpg";
import slide_three from "../../resources/img/slide_three.jpg";
import slide_three_mobile from "../../resources/img/slide_three_mobile.jpg";

const Carousel = () => {
      const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500
      };
    
      
const mediaQuery = window.matchMedia('(max-width: 980px)');
    
      
if (mediaQuery.matches) {
 return (
    <div
      className="carrousel_wrapper"
      style={{
        height: `${window.innerHeight}px`,
        overflow: "hidden"
      }}
    >
      <Slider {...settings}>
        <div>
          <div
            className="carrousel_image"
            style={{
              background: `url(${slide_three_mobile})`,
              height: `${window.innerHeight}px`
            }}
          />
        </div>
        <div>
          <div
            className="carrousel_image"
            style={{
              background: `url(${slide_two_mobile})`,
              height: `${window.innerHeight}px`
            }}
          />
        </div>
        <div>
          <div
            className="carrousel_image"
            style={{
              background: `url(${slide_one_mobile})`,
              height: `${window.innerHeight}px`,
            }}
          />
        </div>
      </Slider>
    </div>
  );
} else {
    return (
    <div
      className="carrousel_wrapper"
      style={{
        height: `${window.innerHeight}px`,
        overflow: "hidden"
      }}
    >
      <Slider {...settings}>
        <div>
          <div
            className="carrousel_image"
            style={{
              background: `url(${slide_one})`,
              height: `${window.innerHeight}px`
            }}
          />
        </div>
        <div>
          <div
            className="carrousel_image"
            style={{
              background: `url(${slide_two})`,
              height: `${window.innerHeight}px`
            }}
          />
        </div>
        <div>
          <div
            className="carrousel_image"
            style={{
              background: `url(${slide_three})`,
              height: `${window.innerHeight}px`,
            }}
          />
        </div>
      </Slider>
    </div>
  );
}
    
  
};

export default Carousel;