import React, {useState, useEffect} from "react";
import Slide from "react-reveal/Slide";

const Timer = () => {
   
  return (
    <Slide left delay={1000}>
      <div className="countdown_wrapper">
        <div className="countdown_top">контакты</div>
        <div className="countdown_bottom">
          <div className="countdown_item">
            <div className="countdown_time">+ 375 29 854-50-47</div>
            <div className="countdown_tag">e-mail: info@bugpro.by</div>
          </div>
        </div>
      </div>
    </Slide>
  );
};

export default Timer;
