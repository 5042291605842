import React from 'react';
import Carousel from './Carousel';
import Timer from './Timer';
const Slider = () => {
  return (
    <div style={{position:'relative'}}>
    <Carousel />
    <Timer/>
    </div>
  );
};

export default Slider;