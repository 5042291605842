export default {
  company: 'BUG-PROJECT',
  company_inf: 'PROJEKTOWANIE, USŁUGI INŻYNIERYJNE',
  copy: {
    p1: 'Absatz mit <b>Html</ b> innen',
    p2: 'Absatz mit anklickbarem %(link)s',
  },

  link: 'Link',
  
  placeholder: 'Eingabe mit Platzhalter',
}