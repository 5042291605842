import React from 'react';
import Zoom from "react-reveal/Zoom";
import MyButton from '../Utils/myButton';

const Pricing = () => {
  const data = [
    {
//      prices: 100,
      positions: "Управление",
      desc: "- КОМПЛЕКСНОЕ управление строительной деятельностью  \n - КОНСУЛЬТАЦИИ по проектированию и строительству \n - РАЗРАБОТКА архитектурно-планировочных концепций (предпроектная документация)",
      linkto: "https://www.facebook.com/367203584048586/services/?service_id=2118442448213011",
      delay: 500
    },
    {
//      prices: 150,
      positions: "Проектирование",
      desc:      
         "- ПРОИЗВОДСТВЕННЫЕ объекты (объекты по выращиванию грибов, логистические центры, хранение и переработка овощной продукции, автомастерские, деревообработка, животноводческие комплексы) \n- ОБЩЕСТВЕННЫЕ здания (офисные здания, торговые центры, здания многофункционального назначения, объекты туризма и отдыха, придорожный сервис)\n- ЖИЛЫЕ здания (многоэтажные жилые дома, многоуровневые парковки)\n- КУЛЬТОВЫЕ здания\n- КОТЕЛЬНЫЕ",
      linkto:
        "https://www.facebook.com/367203584048586/services/?service_id=2253137114696445",
      delay: 0
    },
    {
//      prices: 250,
      positions: "Технический надзор",
      desc:
        "ТЕХНИЧЕСКИЙ НАДЗОР - часть строительного процесса, включающего контроль и надзор заказчика за соответствием строительно-монтажных работ, применяемых материалов, изделий, конструкций и оборудования требованиям действующих ТНПА и утвержденной проектно-сметной документации, а также приемку и учет выполненных работ",
      linkto:
        "https://www.facebook.com/bugpro.by/",
      delay: 500
    }
  ];

    const showBoxes = () =>
      data.map((item, i) => (
        <Zoom delay={item.delay} key={i}>
          <div className="pricing_item">
            <div className="pricing_inner_wrapper">
              <div className="pricing_title">
                  <span>{item.positions}</span>
              </div>
              <div className="pricing_description">
                {item.desc}
              </div>
              
            </div>
          </div>
        </Zoom>
      ));
  return (
    <div className="bck_black">
      <div className="center_wrapper pricing_section">
        <h2>Услуги</h2>

        <div className="pricing_wrapper">{showBoxes()}</div>
      </div>
    </div>
  );
};

export default Pricing;