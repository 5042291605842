import React, { useState, useEffect } from "react";
import MyButton from '../Utils/myButton';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";



const Discount =() => {

  const [discountStart, setDiscountStart] = useState(0);
  const discountEnd = 14;


  const porcentage = () => {
    if (discountStart < discountEnd) {
      setDiscountStart(discountStart + 1)
    }
  };

  useEffect(() => {
    setTimeout(() => {
      porcentage();
    }, 100);
  }, [discountStart]);

    

    return (
      <div className="center_wrapper">
        <div className="discount_wrapper">
          <Fade onReveal={() => porcentage()}>
            <div className="discount_porcentage">
              <span>{discountStart}</span>
              <span>лет на рынке</span>
            </div>
          </Fade>

          <Slide right>
            <div className="discount_description">
              <h3>Компания основана в 2008 году</h3>
              <p>
                За время работы компании успешно реализованы и переданы заказчикам более 500 проектов, в том числе при участии партнеров, которые выступали поставщиками конструкций, оборудования, новых технологий  из таких стран как Россия, Польша, Италия.
              </p>
              <MyButton 
                text="Подробнее"
                bck="#ffa800"
                color="#ffffff"
                link="https://www.facebook.com/bugpro.by/"
              />
            </div>
          </Slide>
        </div>
      </div>
    );
}

export default Discount;
