import React from "react";
import Fade from "react-reveal/Fade";

const Description = () => {
  return (
    <Fade>
      <div className="center_wrapper">
        <h2>Преимущества</h2>
        <div className="highlight_description">
          Заключая договор с БУГ-ПРОЕКТ вы , в первую очередь, получаете гарантию на выполнение работы в оговоренные сроки  с качеством, которое проверено годами. Так же, среди преимуществ нашей компании: квалифицированные специалисты, индивидуальный подход к каждому клиенту и конкурентоспособная цена.
        </div>
      </div>
    </Fade>
  );
};

export default Description;
