import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";

const Footer = () => {
    
    
  return (
    <MDBFooter color="stylish-color-dark" className="font-small pt-4 mt-4">
      <MDBContainer className="text-center text-md-left">
        <MDBRow className="text-center text-md-left mt-3 pb-3">
          <MDBCol md="3" lg="3" xl="3" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Услуги
            </h6>
            <p className="p3"> Проектирование зданий </p>
            <p className="p3"> Комплексное управление</p>
            <p className="p3"> Инженерные услуги</p>
            <p className="p3"> Технический надзор</p>
     
        
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="2" lg="2" xl="2" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">О компании</h6>
            <p className="p3"> ООО "БУГ-ПРОЕКТ"</p>
            <p className="p3"> УНП 290489919 </p>
            <p className="text-center text-md-left grey-text p3">
              &copy; {new Date().getFullYear()} Все права защищены{" "}           
            </p>
                     
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="3" lg="2" xl="2" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Соцсети
            </h6>
             <p className="p3">
              <a className="btn-floating btn-sm rgba-white-slight mx-1 p3" href="https://www.facebook.com/bugpro.by/">
                    <i className="fab fa-facebook-f" />
              </a > <a className="p3" href="https://www.facebook.com/bugpro.by/">facebook </a >
            </p>
            <p className="p3">
              <a className="btn-floating btn-sm rgba-white-slight mx-1 p3" href="https://www.instagram.com/bygproekt/?igshid=1393s7v41rv82">
                    <i className="fab fa-instagram" />
                  </a><a className="p3" href="https://www.instagram.com/bygproekt/?igshid=1393s7v41rv82">instagram </a>
            </p>
            <p className="p3">
               <a className="btn-floating btn-sm rgba-white-slight mx-1">
                    <i className="fab fa-linkedin-in" />
                  </a>linkedin
            </p>
          
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="4" lg="3" xl="3" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">Контакты</h6>
            <p className="p3">
              <i className="fa fa-home mr-3" /> 224005, г.Брест, ул. Комсомольская, 25
            </p>
            <p className="p3">
              <i className="fa fa-envelope mr-3" /> info@bugpro.by
            </p>
            <p className="p3">
              <i className="fa fa-phone mr-3" /> + 375 29 854 50 47
            </p>
            <p className="p3">
              <i className="fa fa-print mr-3" /> + 375 162 208 513
            </p>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow className="d-flex align-items-center">
         
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
}

export default Footer;
