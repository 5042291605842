export default {
  company: 'БУГ-ПРОЕКТ',
  company_inf: 'проектирование, инженерные услуги',
  copy: {
    p1: 'Paragraph with <b>html</b> inside',
    p2: 'Paragraph with clickable %(link)s',
  },

  link: 'link',
  
  placeholder: 'Input with placeholder',
}