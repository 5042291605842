import React from 'react';

const Location = () => {
  return (
    <div className="location_wrapper">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2451.2194343796095!2d23.685907015791635!3d52.093937879735726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47210bf020a93eb1%3A0xabf4cc07a854d730!2z0JHQo9CTLdCf0KDQntCV0JrQoiDQntCe0J4!5e0!3m2!1sru!2sby!4v1568182162260!5m2!1sru!2sby"
        width="100%"
        height="500px"
        allowFullScreen
        title="location"
      />
      <div className="location_tag">
        <div>мы на карте</div>
      </div>
    </div>
  );
};

export default Location;
